<!--<h2 mat-dialog-title>{{ title | translate }}</h2>
<mat-dialog-content class="mat-typography">

  <p style="white-space: pre-wrap;">
    {{ message }}
  </p>  

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial  (click)="onClose()">{{ btnLabel }} </button>
</mat-dialog-actions>-->

<h2 mat-dialog-title>{{ title | translate }}</h2>
<mat-dialog-content class="mat-typography">
    <p style="white-space: pre-wrap;">
        {{ message }}
    </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial (click)="onClose()">
        {{ btnLabel }}
    </button>
</mat-dialog-actions>
