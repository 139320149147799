import { environment } from '../environments/environment';
// import {ModeApplication} from "@quiox/global/models/modeApplication";

export const APP_CONFIG = {
  active_profile: 'prod', // release, prod,dev
  prod: {
    url_gateway: 'https://1weaatrzna.execute-api.us-east-1.amazonaws.com/prod',
    url_hosted_ui: 'https://neunni-prod.auth.us-east-1.amazoncognito.com/login?client_id=2ssspioq9lms6q6a0ls29bmo92&response_type=token&scope=email+openid&redirect_uri=https%3A%2F%2Fcursos.unineuuni.edu.mx%2Fcallback',
    url_logout_hosted_ui: 'https://neunni-prod.auth.us-east-1.amazoncognito.com/logout?client_id=2ssspioq9lms6q6a0ls29bmo92&response_type=token&scope=email+openid&redirect_uri=https%3A%2F%2Fcursos.unineuuni.edu.mx%2Fcallback',
    url_aws_s3: 'https://s3.amazonaws.com/',
    api_recurso: 'https://resourcesapi.production.unineuuni.edu.mx',
    chatUrl: 'https://chatapi.production.unineuuni.edu.mx',
    credentialPublic: 'api/users/credential-by-enrollment',
    // mode_application: ModeApplication.NORMAL
  },
  release: {
    // url_gateway: 'https://gateway.release.unineuuni.edu.mx/test',
    url_gateway: 'https://j53l1nssvf.execute-api.us-east-1.amazonaws.com/test',
    url_hosted_ui:'https://neunni-test.auth.us-east-1.amazoncognito.com/login?client_id=5iaqm3bocmk85vmktmaojofs1m&response_type=token&scope=email+openid&redirect_uri=https%3A%2F%2Fcursos.release.unineuuni.edu.mx%2Fcallback',
    url_logout_hosted_ui:'https://neunni-test.auth.us-east-1.amazoncognito.com/logout?client_id=5iaqm3bocmk85vmktmaojofs1m&response_type=token&scope=email+openid&redirect_uri=https%3A%2F%2Fcursos.release.unineuuni.edu.mx%2Fcallback',
    url_aws_s3: 'https://s3.amazonaws.com/',
    api_recurso: 'https://resourcesapi.release.unineuuni.edu.mx',
    chatUrl: 'https://chatapi.release.unineuuni.edu.mx',
    credentialPublic: 'api/users/credential-by-enrollment',
    // mode_application: ModeApplication.NORMAL
 },
 local: {
    url_gateway: 'https://j53l1nssvf.execute-api.us-east-1.amazonaws.com/test',
    url_hosted_ui:'https://neunni-test.auth.us-east-1.amazoncognito.com/login?client_id=5iaqm3bocmk85vmktmaojofs1m&response_type=token&scope=email+openid&redirect_uri=https%3A%2F%2Fcursosn.neuuni.com%2Fcallback',
    url_logout_hosted_ui:'https://neunni-test.auth.us-east-1.amazoncognito.com/logout?client_id=5iaqm3bocmk85vmktmaojofs1m&response_type=token&scope=email+openid&redirect_uri=https%3A%2F%2Fcursosn.neuuni.com%2Fcallback',
    url_aws_s3: 'https://s3.amazonaws.com/',
    api_recurso: 'https://resourcesapi.release.unineuuni.edu.mx',
 },
  dev: {
      //  Config local
    url_gateway: 'https://localhost:8009',
      url_hosted_ui:'https://neunni-test.auth.us-east-1.amazoncognito.com/login?client_id=23sqeif4q9vmfs5p04930vhc3f&response_type=token&scope=email+openid&redirect_uri=http%3A%2F%2Flocalhost%3A4202%2Fcallback',
      url_logout_hosted_ui:'https://neunni-test.auth.us-east-1.amazoncognito.com/logout?client_id=23sqeif4q9vmfs5p04930vhc3f&response_type=token&scope=email+openid&redirect_uri=http%3A%2F%2Flocalhost%3A4202%2Fcallback',
    // url_hosted_ui:'https://neunni-test.auth.us-east-1.amazoncognito.com/login?client_id=23sqeif4q9vmfs5p04930vhc3f&response_type=token&scope=email+openid&redirect_uri=http%3A%2F%2Flocalhost%3A4202%2Fcallback',
    // url_logout_hosted_ui:'https://neunni-test.auth.us-east-1.amazoncognito.com/logout?client_id=23sqeif4q9vmfs5p04930vhc3f&response_type=token&scope=email+openid&redirect_uri=http%3A%2F%2Flocalhost%3A4202%2Fcallback',
    url_aws_s3: 'https://s3.amazonaws.com/',
    api_recurso: 'https://resourcesapi.release.unineuuni.edu.mx',
    chatUrl: 'http://localhost:8002',
    credentialPublic: 'api/users/credential-by-enrollment',
    // mode_application: ModeApplication.NORMAL
  }
};