
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {FErrorDialogComponent} from '../../main/core/components/f-error-dialog/f-error-dialog.component';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private router: Router, private dialog: MatDialog) {}
  

  /*intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 418) {
          console.error('La aplicación esta en modo de mantenimiento:', error);
          this.router.navigate(['/mantenimiento']);
          
        }
        return throwError(() => error);
      })
    );
  }*/

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                console.error('Error capturado:', error);

                let errorMessage = 'Ocurrió un error inesperado';

                // Verifica si el error tiene un mensaje específico
                if (error.error && error.error.reason) {
                    errorMessage = error.error.reason; // Mensaje del backend
                } else if (error.message) {
                    errorMessage = error.message; // Mensaje genérico de HttpErrorResponse
                }
                const titleMessage = 'Datos incorrectos';
                switch (error.status) {
                    case 418:
                        console.error('La aplicación está en modo de mantenimiento:', error);
                        this.router.navigate(['/mantenimiento']);
                        break;
                    case 400:
                        console.error('Error interno del servidor:', error);
                        this.openErrorDialog(errorMessage, titleMessage);
                        break;
                    default:
                        // this.openErrorDialog(errorMessage, titleMessage);
                        break;
                }

                return throwError(() => error);
            })
        );
    }

    // tslint:disable-next-line:typedef
    private openErrorDialog(message: string, title: string) {
        const dialogRef = this.dialog.open(FErrorDialogComponent, {
            data: { message: message, title: title },
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('El diálogo fue cerrado');
        });
    }
}
