import {Injectable} from '@angular/core';
import {FuseConfirmDialogComponent} from '../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {FValidationErrorDialogComponent} from '../components/f-validation-error-dialog/f-validation-error-dialog.component';
import {FErrorDialogComponent} from '../components/f-error-dialog/f-error-dialog.component';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {SecurityService} from './security.service';

@Injectable({
    providedIn: 'root',
})
export class DialogService {
    private confirmDialog: MatDialogRef<FuseConfirmDialogComponent>;
    private validationErrorDialog: MatDialogRef<FValidationErrorDialogComponent>;
    private errorDialog: MatDialogRef<FErrorDialogComponent>;
    private selectedValue = 'todo';

    constructor(private _matDialog: MatDialog) {
    }

    showConfirmDialog(message?: string, onYes?: (result: any) => void): void {
        this.confirmDialog = this._matDialog.open(FuseConfirmDialogComponent, {
            restoreFocus: false,
            // position: {top: '8%'}
        });

        this.confirmDialog.componentInstance.confirmMessage = message || 'Está seguro que desea continuar?';
        this.confirmDialog.afterClosed().subscribe(result => {
            if (result) {
                onYes(result);
            }
            this.confirmDialog = null;
        });
    }

    showConfirmDialogSabana(message?: string, onYes?: (result: any) => void): void {
        this.confirmDialog = this._matDialog.open(FuseConfirmDialogComponent, {
            restoreFocus: false,
            // position: {top: '8%'}
        });

        this.confirmDialog.componentInstance.confirmMessage = message || 'Está seguro que desea continuar?';
        this.confirmDialog.componentInstance.sabana = true;
        this.confirmDialog.afterClosed().subscribe(result => {
            if (result) {
                onYes(result);
            }
            this.confirmDialog = null;
        });
    }

    showConfirmDialogSabanaAnnouncement(message?: string, onYes?: (result: any) => void, showCancel: boolean = true): void {
        this.confirmDialog = this._matDialog.open(FuseConfirmDialogComponent, {
            restoreFocus: false,
            // position: {top: '8%'}
        });

        this.confirmDialog.componentInstance.confirmMessage = message || 'Está seguro que desea continuar?';
        this.confirmDialog.componentInstance.sabanaAnnouncement = true;
        this.confirmDialog.componentInstance.selectedValue = this.selectedValue;
        this.confirmDialog.afterClosed().subscribe(result => {
            if (result) {
                onYes(result);
            }
            this.confirmDialog = null;
        });
    }

    showDialog(message?: string, onYes?: () => void, showCancel: boolean = true): void {
        this.confirmDialog = this._matDialog.open(FuseConfirmDialogComponent, {
            restoreFocus: false,
            // position: {top: '8%'},
            data: { showCancel: false } // Agrega esta línea para ocultar el botón de cancelar
        });

        this.confirmDialog.componentInstance.confirmMessage = message || 'Está seguro que desea continuar?';
        this.confirmDialog.afterClosed().subscribe(result => {
            if (result) {
                onYes(); // Llama a onYes sin pasar el resultado
            }
            this.confirmDialog = null;
        });
    }

    showConfirmDialogPerfilUsuario(message?: string, onYes?: (result: any) => void): void {

        const student = SecurityService.getUser().roles.includes('Student');
        const teacher = SecurityService.getUser().roles.includes('Teacher');
        // const isTeacher = SecurityService.getUser().roles.includes('Teacher');
        const coordinator = SecurityService.getUser().roles.includes('Coordinator');
        this.confirmDialog = this._matDialog.open(FuseConfirmDialogComponent, {
            restoreFocus: false,
            data: {
                student: student,
                teacher: teacher,
                coordinator: coordinator
            }
        });

        this.confirmDialog.componentInstance.confirmMessage = message || 'Está seguro que desea continuar?';
        this.confirmDialog.componentInstance.perfilUser = true;
        this.confirmDialog.afterClosed().subscribe(result => {
            if (result) {
                onYes(result);
            }
            this.confirmDialog = null;
        });
    }


    showConfirmDialog1(message?: string, title?: string): Promise<any> {
        this.confirmDialog = this._matDialog.open(FuseConfirmDialogComponent, {restoreFocus: false});
        this.confirmDialog.componentInstance.confirmMessage = message || '¿Está seguro que desea continuar?';
        this.confirmDialog.componentInstance.title = title || 'Confirmar';

        return new Promise((resolve, reject) => {
            this.confirmDialog.afterClosed().subscribe(result => {
                resolve(result);
            });
            this.confirmDialog = null;
        });
    }

    showValidationErrorDialog(errors: any): void {
        this.validationErrorDialog = this._matDialog.open(FValidationErrorDialogComponent);
        this.validationErrorDialog.componentInstance.errors = errors;

        this.validationErrorDialog.afterClosed().subscribe(result => {
            this.validationErrorDialog = null;
        });
    }

    /*showErrorDialog(message: string, title?: string, config: {btnLabel?: string, matDialogConfig: {}} = {btnLabel: 'Cerrar', matDialogConfig: {}}): Promise<void> {
        this.errorDialog = this._matDialog.open(FErrorDialogComponent, {
            minWidth: '350px',
            restoreFocus: false,
            ... config.matDialogConfig
        });

        this.errorDialog.componentInstance.message = message;
        if (title) {
            this.errorDialog.componentInstance.title = title;
        }
        this.errorDialog.componentInstance.btnLabel = config.btnLabel;

        return new Promise((resolve, reject) => {
            this.errorDialog.afterClosed().subscribe(() => {
                this.errorDialog = null;
                resolve();
            });
        });
    }*/

    showErrorDialog(
        message: string,
        title?: string,
        config: { btnLabel?: string; matDialogConfig?: MatDialogConfig } = {
            btnLabel: 'Cerrar',
            matDialogConfig: {}
        }
    ): Promise<void> {
        // Configuración para centrar el diálogo
        const baseConfig: MatDialogConfig = {
            minWidth: '350px',
            restoreFocus: false,
            position: {
                top: '50vh',    // 50% del alto de la pantalla
                left: '50%'     // 50% del ancho de la pantalla
            },
            data: { // Pasamos todos los datos aquí
                message: message,
                title: title,
                btnLabel: config.btnLabel
            },
            ...config.matDialogConfig // Configuraciones adicionales del usuario
        };

        this.errorDialog = this._matDialog.open(FErrorDialogComponent, baseConfig);

        return new Promise((resolve) => {
            this.errorDialog.afterClosed().subscribe(() => {
                this.errorDialog = null;
                resolve();
            });
        });
    }

}
